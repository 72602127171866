import React from 'react';
import styled from 'styled-components';
import PicHands from 'imgs/1d1d/pc/partner.condition.png';

const Wrapper = styled.section`
  padding: 0.75rem 0.6rem 0.72rem;
  .heads {
    margin-bottom: 0.6rem;
    text-align: center;
    > h2 {
      color: #969696;
      font-size: 0.166rem;
      text-transform: uppercase;
    }
    > h3 {
      color: #636363;
      font-size: 0.302rem;
      font-weight: bold;
      margin-bottom: 0.14rem;
    }
    .line {
      width: 0.95rem;
      height: 2px;
      background-color: #ff7c34;
      margin: 0.2rem auto 0;
    }
  }
  .info {
    > img {
      width: 100%;
    }
    .txt {
      display: flex;
      flex-direction: column;
      .five {
        > p {
          color: #636363;
          font-size: 0.2rem;
          display: flex;
          .num {
            position: relative;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            line-height: 0.45rem;
            color: #ff7c34;
            width: 0.45rem;
            height: 0.45rem;
            border: 1px solid #ff7c34;
            border-radius: 50%;
            margin-right: 0.34rem;
          }
          span {
            width: calc(100% - 0.79rem);
          }
          &:not(:last-child) {
            margin-bottom: 0.62rem;
            .num:after {
              position: absolute;
              content: '';
              left: 50%;
              transform: translateX(-50%);
              bottom: -0.5rem;
              display: block;
              width: 1px;
              height: 0.34rem;
              background: #ff7c34;
            }
          }
        }
      }
      .sayno {
        background-image: linear-gradient(#f5f5f5, #f5f5f5), linear-gradient(#ffffff, #ffffff);
        background-blend-mode: normal, normal;
        border-radius: 0.1rem;
        text-align: center;
        padding-bottom: 0.66rem;
        margin-top: 0.62rem;
        h3 {
          color: #2c2c2c;
          font-size: 0.26rem;
          margin-top: 0.54rem;
          margin-bottom: 0.36rem;
        }
        > p {
          color: #636363;
          font-size: 0.2rem;
          &:not(:last-child) {
            margin-bottom: 0.2rem;
          }
        }
      }
    }
  }
`;
const Conditions = [
  '热爱幼教，热爱孩子；',
  '做人诚实，做事规范，遇事包容，崇尚共赢；',
  '有团队，能打仗；',
  '曾服务过100家以上幼儿园；',
  '区域幼教集团投资人、总园长优先；\n区域民办幼教协会重大贡献者优先。'
];

export default function Features() {
  return (
    <Wrapper>
      <div className="heads">
        <h3 data-aos="fade-right">地级市合伙人招募条件</h3>
        <h2 data-aos="fade-left">recruitment conditions</h2>
        <p className="line"></p>
      </div>
      <div className="info">
        <img data-aos="flip-up" src={PicHands} alt="条件配图" />
        <div className="txt">
          <div className="five">
            {Conditions.map((c, idx) => {
              return (
                <p key={c}>
                  <span className="num">{idx + 1}</span>
                  <span>{c}</span>
                </p>
              );
            })}
          </div>
          <div className="sayno">
            <h3>三类合伙人我们坚决不要</h3>
            <p>第一，没有团队，或者不会带团队的；</p>
            <p>第二，懂商业但不懂教育的；</p>
            <p>第三，只求利己不求利他的。</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
