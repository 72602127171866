import React from 'react';
import styled from 'styled-components';
const Wrapper = styled.div`
  height: 0.28rem;
  background: #fafafa;
`;
const Gap = () => {
  return <Wrapper />;
};
export default Gap;
