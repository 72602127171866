import React from 'react';
import styled from 'styled-components';
import PicChart from 'imgs/1d1d/pc/partner.chart.png';
import QRImg from 'imgs/1d1d/pc/partner.qr.png';
import ZJImg from 'imgs/1d1d/pc/partner.zj.png';

const Wrapper = styled.section`
  padding: 0.75rem 0.72rem 0.7rem;
  background: #fafafa;
  .heads {
    text-align: center;
    > h2 {
      color: #969696;
      font-size: 0.166rem;
      text-transform: uppercase;
    }
    > h3 {
      color: #636363;
      font-size: 0.302rem;
      font-weight: bold;
      margin-bottom: 0.14rem;
    }
    .line {
      width: 0.95rem;
      height: 2px;
      background-color: #ff7c34;
      margin: 0.2rem auto 0;
    }
  }
  .info {
    > img {
      width: 64%;
      margin: 0.53rem auto 0.68rem;
      display: block;
    }
    .txt {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      font-size: 0.2rem;
      > p {
        line-height: 1.6;
        &.tel {
          font-size: 0.24rem;
          color: #636363;
          font-weight: bold;
          margin-top: 0.8rem;
        }
      }
    }
    .QrCode {
      display: inline-block;
      width: 1.65rem;
      height: 1.65rem;
      margin-right: 0.3rem;
    }
  }
`;

export default function BePartner() {
  return (
    <Wrapper>
      <div className="heads">
        <h3 data-aos="fade-right">立即成为区域合伙人</h3>
        <h2 data-aos="fade-left">Regional partner</h2>
        <p className="line" />
      </div>
      <div className="info">
        <img src={PicChart} data-aos="zoom-in" alt="成为合伙人配图" />
        <div className="txt">
          <p>
            {/* 和紫荆儿童成长云平台 暂时注释 */}
            在我们眼里，区域合伙人是兄弟，是姐妹；是壹点壹滴在各地级市的首席代表，
            是未来中国幼教互联网区域入口的服务者、价值贡献者。
          </p>
          <p>
            {/* ，认可紫荆儿童成长云平台 */}
            如果你认可壹点壹滴，请速扫描下方二维码报名，成为我们的合伙人！
          </p>
          <p>给我一个机会，还你一个惊喜！</p>
          <p className="tel">咨询热线 : 400-666-7689</p>
        </div>
        <img src={QRImg} alt="二维码" className="QrCode" />
        <img src={ZJImg} alt="二维码" className="QrCode" />
      </div>
    </Wrapper>
  );
}
